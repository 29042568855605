import React from 'react';
// import { HiArrowNarrowRight } from 'react-icons/hi';
import ManoshanImg from '../assets/ManoshanDom0.png';
import LinkButton from './LinkButton';

// import { Link } from 'react-scroll';

const Home = () => {
  return (
    <div
      name="home"
      className=" bg-[#3f3319] w-full lg:h-[1000px] md:h-[1100px] h-[1400px] sm:h-[1200px] pt-24"
    >
      {/* Home Container */}
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10 justify-center h-full sm:h-full bg-yellow-50 rounded-xl shadow-xl shadow-[#92866c]">
        <p className="text-[#000000] pt-[50px] sm:pt-20">
          <strong>Hi there! I'm</strong>
        </p>
        <h1 className="text-4xl md:text-7xl font-bold text-[#3f3319]">
          Manoshan Domingo
        </h1>
        <h2 className="text-3xl md:text-5xl font-bold text-[#b49d6b]">
          I am a Project Manager
        </h2>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-10 pt-[30px] ">
          <div className="w-full ">
            <p className="text-[#000000] py-4 max-w-[100%] pt-10">
              With a rich and accomplished career spanning over 30 years, I am a
              seasoned and integrity-driven professional dedicated to
              orchestrating success in many industries. My expertise lies in the
              dynamic delivery of innovative, customer-responsive projects
              across diverse business domains. From navigating the complexities
              of Weather and Climate, Mining, and Geology to optimizing Port and
              Container Management, delving into the intricacies of Publishing
              and Telecommunication, and steering through the intricacies of
              Banking and Finance, my journey has been marked by a commitment to
              excellence and a passion for driving impactful solutions. My
              multifaceted experience has equipped me with a holistic
              understanding of various sectors, allowing me to meet and exceed
              clients' evolving needs consistently.
            </p>
          </div>
          {/* <div className="bg-gray-500 sm:w-[300px] sm:h-[300px] pt-10"> */}
          <div className="mx-auto w-[200px] h-[200px] sm:w-[80%] sm:pt-10 ">
            <img
              src={ManoshanImg}
              className="shadow-xl rounded-xl p-4"
              alt="Manoshan Domingo"
            />
          </div>
        </div>
        <div className="pt-4 font-bold">
          <LinkButton linktitle="View Work" destination="work" />
        </div>
      </div>
      {/* <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto justify-center ">
        <LinkButton />
      </div> */}
    </div>
  );
};

export default Home;
