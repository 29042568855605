import React from 'react';
import { Link } from 'react-scroll';
import { HiArrowNarrowRight } from 'react-icons/hi';

const LinkButton = (props) => {
  return (
    <div className="pb-[40px]">
      <button className="text-[#3f3319] hover:text-white text-[15px] group boder-2 px-6 py-3 my-2 flex items-center bg-[#b49d6b] border-[#b49d6b] rounded-full shadow-xl shadow-[#92866c]">
        <Link to={props.destination} smooth={true} duration={500}>
          {props.linktitle}
        </Link>
        {/* 
        <span className="group-hover:rotate-90 duration-300">         */}
        <span
          className={
            props.turning === 'up'
              ? 'group-hover:-rotate-90 duration-300'
              : 'group-hover:rotate-90 duration-300'
          }
        >
          <HiArrowNarrowRight className="ml-3" />
        </span>
      </button>
    </div>
  );
};

export default LinkButton;
