import React from 'react';
import LinkButton from './LinkButton';

const DepPrimaryInd = () => {
  return (
    <div
      name="depprimaryind"
      className=" bg-[#3f3319] w-full lg:h-[1300px] md:h-[1600px] sm:h-[1250px] h-[2400px] items-center pt-24"
    >
      {/* Bureau Experience Container */}
      {/* justify-center */}
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10  lg:h-[1200px] md:h-[1500px] sm:h-[1250px] h-[2300px] bg-yellow-50 rounded-xl shadow-xl shadow-[#40403f]">
        {/* Bureau Experience Main Header */}
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pt-[50px] sm:pt-4">
            <p className="text-4xl font-bold inline border-b-4 text-[#3f3319] border-[#3f3319]">
              Department of Primary INDUSTRIES
            </p>
          </div>
          <div></div>
        </div>

        {/* Tow column grid when greater than small screens */}
        <div className="max-w-[1000px] grid sm:grid-cols-10 justify-items-stretch px-2 gap-8">
          {/* First Column is 30% or one third of the screen */}
          <div className="sm:col-span-3">
            <div className="pt-3">
              From <strong>2006 March</strong>
            </div>
            <div>
              to <strong>2008 June</strong>
            </div>
          </div>

          {/* Second column is two third or 70% of the screen */}
          <div className="sm:col-span-7">
            <p className="pb-3 pt-3">
              At the heart of Victoria's vibrant ecosystem, the Department of
              Primary Industries (DPI) plays a pivotal role in shaping a
              sustainable future. DPI's mission is clear: to promote the
              sustainable development of primary industries, ensuring they serve
              as a cornerstone for the well-being and prosperity of all
              Victorians. DPI is dedicated to enhancing the agricultural,
              fisheries, and forestry sectors by embracing innovation, fostering
              resilience, and prioritising sustainability. DPI's efforts are
              geared towards economic growth and ensuring environmental health
              and community vitality for future generations.
            </p>

            <p className="pb-3">
              <strong>Key Projects: </strong>
              GUS (Geological Unit System), and MIMIS II (Mining Inspector
              Management Information System) As a Senior Project Manager, I had
              the privilege of leading two significant initiatives: the
              development of the Geological Unit System (GUS), which had a
              project budget of <strong>$2.5 million</strong>, and the
              comprehensive development of the Mining Inspector Management
              Information System (MIMIS II). These projects encompassed a full
              spectrum of responsibilities, from requirements gathering and
              analysis to design, development, unit testing, implementation,
              user training, and integration with GIS tools.
            </p>

            <p className="pb-3">
              <strong>Team Leadership:</strong> Successfully managed and led a
              new, multicultural team, fostering a collaborative and inclusive
              work environment that capitalized on diverse perspectives and
              expertise.
            </p>
            <p className="pb-3">
              <strong>Process Optimization:</strong> Streamlined team activities
              and project workflows, ensuring efficiency and coherence across
              all project phases.
            </p>
            <p className="pb-3">
              <strong>Methodology Implementation:</strong> I introduced and
              implemented RUP (Rational Unified Process) and Agile software
              development methodologies, significantly shifting towards more
              flexible and responsive project management practices.
            </p>
            <p className="pb-3">
              <strong>Project Planning:</strong> I addressed and resolved
              complexities within the project plan. I restructured the approach
              to adhere to the RUP software development methodology coupled with
              Agile multi-iterations, optimizing project delivery and outcomes.
            </p>

            <p className="pb-3">
              Through strategic Leadership and innovative project management
              practices, I successfully steered these projects to completion,
              underscoring my commitment to excellence and ability to navigate
              and manage complex, high-stakes initiatives.
            </p>

            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319] pt-4"></div>
            <div className="pt-4 font-bold">
              <LinkButton linktitle="Work" destination="work" turning="up" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepPrimaryInd;
