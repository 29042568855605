import React from 'react';
import LinkButton from './LinkButton';

const Rc82Website = () => {
  return (
    <div
      name="rc82website"
      className="w-full lg:h-[1250px] md:h-[1600px] sm:h-[1250px] h-[2100px] pt-24 bg-[#3f3319] text-gray-400"
    >
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10 lg:h-[1150px] md:h-[1500px] sm:h-[1150px] h-full  bg-yellow-50 rounded-xl shadow-xl shadow-[#92866c]">
        {/* Work Experience Main Header */}
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pt-[50px] sm:pt-4">
            <p className="text-4xl font-bold inline border-b-4 text-[#3f3319] border-[#3f3319]">
              RC 82 Group Website
            </p>
          </div>
          <div></div>
        </div>

        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          {/* Manoshan Porfolio Website */}
          <div className="sm:text-left text-3xl text-[#b49d6b] w-[80%]">
            <p className=" pb-[20px]">Royal College 82 Group Website</p>
            <div className="sm:text-left pl-[30px]  text-xl justify-self-start text-[#b49d6b] w-[80%] ">
              <p className="">
                <strong>Start Date: </strong>Feb 2024
              </p>
              <p>
                <strong>End Date: </strong>Feb 2024
              </p>
              <div></div>
            </div>
          </div>

          <div>
            <p className="text-[#7a6d52]  py-4 max-w-[700px]">
              In 2018, I embarked on a journey to delve into Drupal 8, and what
              better way to learn than through a real-world project? This led to
              the creation of the Royal College Group of 82 website. Exploring
              Drupal sites was intriguing, but practical application was vital.
            </p>
            <p className="text-[#7a6d52]  py-4 max-w-[700px]">
              As fate would have it, my schoolmates sought a solution for
              maintaining records of batch mates and teachers from our school
              days. This presented the perfect opportunity to apply my Drupal
              expertise. I eagerly volunteered to develop the site, putting my
              skills into action.{' '}
            </p>
            <p className="text-[#7a6d52]  py-4 max-w-[700px]">
              Armed with gathered information, I commenced the project. The
              outcome exceeded expectations. At an Australian batch mate
              gathering, I proudly unveiled the website, quickly gaining
              popularity among colleagues and teachers. As the site
              administrator, I received invaluable contributions from batchmates
              and teachers.
            </p>
            Amidst the challenges of the COVID lockdown, I seized the
            opportunity to upgrade the site to Drupal 10, ensuring its continued
            relevance and functionality. ---
            <p>
              This version maintains the essence of your story while presenting
              it in a more engaging and web-friendly format.
            </p>
            <p className="text-[#7a6d52]  py-4 max-w-[700px]">
              I used the following resources to learn React:
              <ul>
                <li>Udemy web development course library,</li>
                <li>YouTube - followed several React tutorials, and</li>
                <li>TailWind CSS website etc.</li>
              </ul>
            </p>
            <p className="text-[#7a6d52]  py-4 max-w-[700px]">
              <strong>Note: </strong>Please let me know any suggestions you
              identified by scanning through my site. I am not perfect, but I
              can improve the site with your feedback. Please use the contact
              form to send me your suggestions.
            </p>
            <div className="pt-4 font-bold">
              <LinkButton
                linktitle="My Projects"
                destination="projects"
                turning="up"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rc82Website;
