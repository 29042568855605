import React from 'react';
import LinkButton from './LinkButton';

const Immersive = () => {
  return (
    <div
      name="immersive"
      className=" bg-[#3f3319] w-full lg:h-[1250px] md:h-[1500px] sm:h-[1200px] h-[2400px] items-center pt-24"
    >
      {/* Bureau Experience Container */}
      {/* justify-center */}
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10  lg:h-[1150px] md:h-[1400px] sm:h-[1200px] h-[2300px] bg-yellow-50 rounded-xl shadow-xl shadow-[#40403f]">
        {/* Bureau Experience Main Header */}
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pt-[50px] sm:pt-4">
            <p className="text-4xl font-bold inline border-b-4 text-[#3f3319] border-[#3f3319]">
              Immersive Pty Ltd
            </p>
          </div>
          <div></div>
        </div>

        {/* Tow column grid when greater than small screens */}
        <div className="max-w-[1000px] grid sm:grid-cols-10 justify-items-stretch px-2 gap-8">
          {/* First Column is 30% or one third of the screen */}
          <div className="sm:col-span-3">
            <div className="pt-3">
              From <strong>2008 June</strong>
            </div>
            <div>
              to <strong>2008 October</strong>
            </div>
          </div>

          {/* Second column is two third or 70% of the screen */}
          <div className="sm:col-span-7">
            <p className="pb-3 pt-3">
              As the Senior Project Manager, I was pivotal in optimizing project
              management practices and overseeing the allocation of engineering
              resources across the organization. Focusing on driving efficiency
              and maximizing productivity, I spearheaded the streamlining and
              implementing robust project management processes companywide. By
              aligning project workflows with strategic objectives and
              diligently managing engineering resources, I ensured smooth
              project execution and delivery. Through effective leadership and
              strategic planning, I fostered a culture of excellence,
              innovation, and continuous improvement within the organization. I
              remain committed to driving operational excellence and delivering
              successful outcomes in every project endeavour.
            </p>

            <p className="pb-3">
              As the project lead for the Text Pacific initiative, I held full
              accountability for facilitating the consolidation and publication
              of <strong>National Australia Bank's (NAB)</strong> financial
              publications within strict timelines. This encompassed overseeing
              the Profit Announcement (PA), the Annual Financial Report (AFR),
              and the maintenance of NAB's Financial Groups Content Management
              System (CMS). The project lifecycle involved meticulous stages
              from requirements gathering to implementation; all managed within
              a budget of <strong>$150K</strong>. Key achievements include:
            </p>

            <p className="pb-3">
              <strong>Efficient Resource Management:</strong> Successfully
              managed companywide engineering resources, optimizing their
              allocation to maximize productivity and ensure project milestones
              were met on time.
            </p>
            <p className="pb-3">
              <strong>Streamlining Project Management:</strong> Introduced and
              streamlined project management processes, enhancing efficiency,
              transparency, and alignment with project objectives.
            </p>
            <p className="pb-3">
              <strong>Risk Mitigation Strategies:</strong> We consolidated the
              risks and issues associated with the project and implemented
              proactive mitigation plans to minimize potential disruptions and
              ensure smooth project progression.
            </p>
            <p className="pb-3">
              <strong>Governance Best Practices:</strong> Implemented best
              practices in project governance, fostering accountability,
              adherence to industry standards, and effective decision-making
              processes.
            </p>

            <p className="pb-3">
              Through these efforts, we achieved project success and improved
              NAB's financial publishing capabilities. I remain dedicated to
              driving excellence and innovation in every project endeavour.{' '}
            </p>

            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319] pt-4"></div>
            <div className="pt-4 font-bold">
              <LinkButton linktitle="Work" destination="work" turning="up" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Immersive;
