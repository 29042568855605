import React from 'react';
import LinkButton from './LinkButton';

const About = () => {
  return (
    <div
      name="about"
      className=" bg-[#3f3319] w-full lg:h-[1450px] md:h-[1800px] sm:h-[1200px] h-[2300px] pt-24"
    >
      {/* About Container */}
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10 justify-center lg:h-[1350px] md:h-[1700px] h-full sm:h-full bg-yellow-50 rounded-xl shadow-xl shadow-[#92866c]">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className=" sm:text-right pb-12 pl-4 pt-[50px]">
            <p className="text-4xl font-bold inline border-b-4 text-[#3f3319] border-[#3f3319]">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 px-4">
          <div className="sm:text-right font-bold text-3xl text-[#b49d6b] w-[80%]">
            <p>
              Hi, I am Manoshan. Do you want to know more about me? Please look
              around.
            </p>
          </div>
          <div>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              I am well known as Manoshan Domingo, a seasoned professional with
              a diverse and enriching career spanning over three decades.
              Originally from Sri Lanka, I began my professional journey in 1989
              as a medical representative. This early experience gave me a
              foundational understanding of the healthcare industry and sparked
              my curiosity about my career's various paths.
            </p>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              After a brief but insightful stint in the medical field, I
              transitioned into the dynamic realm of banking. Over four and a
              half years, I honed my skills, progressing from a banker to an
              integral role in IT operations for a leading bank in Sri Lanka.
              This marked the beginning of a sixteen-year-long journey within
              the banking sector, during which I seamlessly moved through roles
              such as a banking software developer and ultimately concluded my
              tenure as a proficient project manager.
            </p>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              My pivot towards the Information Technology stream during my
              banking career opened up new horizons, leading me to a global
              software company. This venture took me to the bustling cities of
              New York and Boston in the United States, as well as Chennai,
              India, where I further refined my expertise in software
              development.
            </p>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              Having migrated to Australia, my professional landscape shifted
              towards the public sector. Currently, I am proud to contribute to
              the Bureau of Meteorology as a senior project manager. In this
              capacity, I draw upon my extensive background in both banking and
              software to navigate complex projects, ensuring the successful
              delivery of initiatives crucial to the organization's mission.
            </p>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              My journey reflects a commitment to continuous learning,
              adaptability, and a passion for driving successful outcomes. I am
              excited about the opportunities ahead and look forward to
              leveraging my wealth of experience to contribute to innovative and
              impactful projects.
            </p>
          </div>
          <div className="pt-4 font-bold">
            <LinkButton linktitle="Home" destination="home" turning="up" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
