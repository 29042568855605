import React from 'react';
import LinkButton from './LinkButton';

const Virtusa = () => {
  return (
    <div
      name="virtusa"
      className=" bg-[#3f3319] w-full lg:h-[2150px] md:h-[2600px] sm:h-[2150px] h-[4150px] items-center pt-24"
    >
      {/* Bureau Experience Container */}
      {/* justify-center */}
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10  lg:h-[2050px] md:h-[2500px] sm:h-[2150px] h-[4050px] bg-yellow-50 rounded-xl shadow-xl shadow-[#40403f]">
        {/* Bureau Experience Main Header */}
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pt-[50px] sm:pt-4">
            <p className="text-4xl font-bold inline border-b-4 text-[#3f3319] border-[#3f3319]">
              Virtusa Pty LTD.
            </p>
          </div>
          <div></div>
        </div>

        {/* Tow column grid when greater than small screens */}
        <div className="max-w-[1000px] grid sm:grid-cols-10 justify-items-stretch px-2 gap-8">
          {/* First Column is 30% or one third of the screen */}
          <div className="sm:col-span-3">
            <div className="pt-3">
              From <strong>2002 May</strong>
            </div>
            <div>
              to <strong>2006 January</strong>
            </div>
          </div>

          {/* Second column is two third or 70% of the screen */}
          <div className="sm:col-span-7">
            <p className="pb-3 pt-3">
              I am proud to have been a key part of a global software
              development and IT services powerhouse, distinguished by a dynamic
              team of over 2,000 professionals stationed worldwide. At the time
              of my departure, the company had achieved an impressive milestone:
              annual revenues exceeded <strong>US$60 million</strong>. This
              achievement stands as a testament to our commitment to excellence,
              innovation, and the relentless pursuit of delivering unparalleled
              value to our clients worldwide.
            </p>

            <p className="pb-3">
              Project: <strong>IM3 Hot House</strong> for{' '}
              <strong>British Telecom (BT)</strong> Location:{' '}
              <strong>Chennai, India</strong>
              Duration: <strong>2005 August</strong> to{' '}
              <strong>2005 December</strong> As the driving force behind the
              offshore operations for British Telecom's (BT) prestigious IM3 Hot
              House project, I spearheaded a comprehensive approach covering all
              project lifecycle phases. My leadership was instrumental in
              incorporating agile methodologies and XP programming,
              significantly enhancing our ability to deliver frequent updates
              and meet stringent 90-day customer deadlines.
            </p>

            <p className="pb-3">
              <strong>Key Responsibilities and Achievements:</strong>
            </p>
            <p className="pb-3">
              <strong>Team Leadership:</strong> Managed a dedicated{' '}
              <strong>26-member</strong>
              offshore team, including five lead roles, overseeing five major
              supporting applications for BT. This robust team structure was
              pivotal in facilitating our success and ensuring seamless
              operations.
            </p>
            <p className="pb-3">
              <strong>Revenue Generation:</strong> The team successfully
              generated <strong>US$750K</strong> in revenue for the quarter, a
              testament to their hard work and dedication to exceeding BT's
              expectations.
            </p>
            <p className="pb-3">
              <strong>Communication and Coordination:</strong> I was responsible
              for vital on-site-offshore communications, ensuring clear,
              concise, and continuous stakeholder dialogue. This role was
              crucial in bridging gaps and maintaining project alignment with
              business objectives.
            </p>
            <p className="pb-3">
              <strong>Project Tracking and Monitoring:</strong> Effective
              project tracking and monitoring practices were implemented,
              enabling a transparent view of progress, timely identification of
              potential risks and issues, and the development of appropriate
              mitigation plans.
            </p>
            <p className="pb-3">
              <strong>Stakeholder Updates:</strong> I provided regular progress
              updates to the on-site team and Virtusa leadership, ensuring all
              parties were informed of project status, milestones achieved, and
              upcoming objectives.
            </p>
            <p className="pb-3">
              <strong>Financial Management:</strong> I oversaw the project's
              billing and forecasting, ensuring financial accountability and
              transparency throughout its lifecycle.
            </p>
            <p className="pb-3">
              This role demanded high technical and managerial expertise and a
              deep commitment to meeting client needs through innovative
              solutions and agile responses to changing requirements.
            </p>

            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319] pt-4"></div>

            <p className="pb-3">
              <strong>Offshore Project Manager</strong>
            </p>
            <p className="pb-3">
              Duration: <strong>2005 March</strong> to{' '}
              <strong>2005 August</strong>
            </p>
            <p className="pb-3">
              As an offshore project manager, I played a pivotal role in the
              transformative company-restructuring program that established the
              <strong> Global Enterprise Business Unit (GEBU)</strong>. My
              contributions were central to this newly minted division's
              strategic planning, budgeting, and resourcing, ensuring a robust
              foundation for its operational success.
            </p>

            <p className="pb-3">
              <strong>Key Contributions:</strong>
            </p>
            <p className="pb-3">
              <strong>Strategic Collaboration:</strong> Worked closely with the
              strategic management level, playing a crucial role in the
              conceptualization and operational strategy of the GEBU. My
              involvement ensured that the unit's formation was aligned with the
              company's long-term goals and objectives.
            </p>

            <p className="pb-3">
              <strong>Financial Planning:</strong> Spearheaded the preparation
              of revenue budgets, successfully outlining a US$7.0 Million budget
              for the third quarter. This critical task underscored my ability
              to manage finances effectively, ensuring the unit's projects were
              well-funded and positioned for success.
            </p>

            <p className="pb-3">
              <strong>Resource Estimation and Capacity Planning:</strong>{' '}
              Conducted comprehensive resource requirement estimations and
              capacity planning for the quarter. My efforts in this area
              guaranteed that the business unit was adequately staffed, with
              resources optimally allocated to meet project demands.
            </p>
            <p className="pb-3">
              <strong>Talent Acquisition:</strong> Led the hiring initiatives
              for the projects under the GEBU. By identifying and onboarding
              essential talents, I contributed to building a capable and dynamic
              team ready to tackle the challenges of the new business unit.
            </p>
            <p className="pb-3">
              This role emphasized my project management capabilities, strategic
              planning, and financial acumen, which contributed significantly to
              the company's evolution and the successful launch of the GEBU.
            </p>

            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319] pt-4"></div>
            <div className="pt-4 font-bold">
              <LinkButton linktitle="Work" destination="work" turning="up" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Virtusa;
