import React, { useState } from 'react';
import {
  FaBars,
  FaFacebook,
  FaGithub,
  FaLinkedin,
  FaTimes,
} from 'react-icons/fa';
import Logo2 from '../assets/ManosahnDom-Text.png'; //Text Image
import Logo from '../assets/ManoshanDom0.png'; //photo Image
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div
      name="navbar"
      className="fixed w-full h-[80px] flex justify-between items-center px-4  bg-[#6b5117] text-gray-300"
    >
      {/* NavBar Logo ---------------------------------------------------- */}
      {/* I have included two logos one for the image and one for the name */}
      <div>
        <img src={Logo} alt="Logo" style={{ width: '70px' }} />
      </div>
      <div className="pl-50px md:w-[130px] lg:w-[400px]">
        <img src={Logo2} alt="Logo" style={{ width: '200px' }} />
      </div>
      {/* Large screen menu */}
      <div>
        <ul className="hidden md:flex">
          <li>
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li>
            <Link to="about" smooth={true} duration={500}>
              About
            </Link>
          </li>
          <li>
            <Link to="work" smooth={true} duration={500}>
              Work Experience
            </Link>
          </li>
          <li>
            <Link to="projects" smooth={true} duration={500}>
              My Projects
            </Link>
          </li>
          <li>
            <Link to="contact" smooth={true} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
      </div>

      {/* Hamburger */}
      <div onClick={handleClick} className="block md:hidden z-10">
        {nav ? <FaTimes /> : <FaBars />}
      </div>

      {/* Mobile Menu */}
      <div
        className={
          nav
            ? 'fixed left-0 top-0 w-[60%] h-full boder-r border-r-gray-900 bg-[#114243] ease-in-out duration-500'
            : 'fixed left-[-100%]'
        }
      >
        {/* <ul
          className={
            !nav
              ? 'hidden'
              : 'absolute top-0 left-0 w-full h-screen bg-[#114243] flex flex-col justify-center items-center'
          }
        > */}
        <ul className="uppercase p-4">
          <li className="py-6 text-4xl">
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className="py-6 text-4xl">
            <Link to="about" smooth={true} duration={500}>
              About
            </Link>
          </li>
          <li className="py-6 text-4xl">
            {' '}
            <Link to="projects" smooth={true} duration={500}>
              My Projects
            </Link>
          </li>
          <li className="py-6 text-4xl">
            {' '}
            <Link to="work" smooth={true} duration={500}>
              Work Experience
            </Link>
          </li>
          <li className="py-6 text-4xl">
            {' '}
            <Link to="contact" smooth={true} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
      </div>

      {/* Social Icons */}
      <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-500">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.linkedin.com/in/manoshan-domingo-5838a59/"
            >
              Linkedin <FaLinkedin size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="/"
            >
              Github <FaGithub size={30} />
            </a>
          </li>{' '}
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="/"
            >
              Email <HiOutlineMail size={30} />
            </a>
          </li>{' '}
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="/"
            >
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-700">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.facebook.com/manoshandomingo"
            >
              FaceBook <FaFacebook size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
