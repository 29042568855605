import React from 'react';
import LinkButton from './LinkButton';

const Harbinger = () => {
  return (
    <div
      name="harbinger"
      className=" bg-[#3f3319] w-full lg:h-[1300px] md:h-[1550px] sm:h-[1300px] h-[2500px] items-center pt-24"
    >
      {/* Bureau Experience Container */}
      {/* justify-center */}
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10  lg:h-[1200px] md:h-[1450px] sm:h-[1300px] h-[2400px] bg-yellow-50 rounded-xl shadow-xl shadow-[#40403f]">
        {/* Bureau Experience Main Header */}
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pt-[50px] sm:pt-4">
            <p className="text-4xl font-bold inline border-b-4 text-[#3f3319] border-[#3f3319]">
              Harbinger Pty Ltd
            </p>
          </div>
          <div></div>
        </div>

        {/* Tow column grid when greater than small screens */}
        <div className="max-w-[1000px] grid sm:grid-cols-10 justify-items-stretch px-2 gap-8">
          {/* First Column is 30% or one third of the screen */}
          <div className="sm:col-span-3">
            <div className="pt-3">
              From <strong>2009 January</strong>
            </div>
            <div>
              to <strong>2009 May</strong>
            </div>
          </div>

          {/* Second column is two third or 70% of the screen */}
          <div className="sm:col-span-7">
            <p className="pb-3 pt-3">
              In my role as a project manager at our esteemed organization, I
              had the privilege of leading the State Library of Victoria's (SLV)
              corporate website redevelopment project. Tasked with revitalizing
              SLV's online presence, I spearheaded efforts to streamline our
              project management processes, ensuring efficiency and
              effectiveness every step of the way. Moreover, I took on the
              crucial responsibility of empowering SLV resources, providing
              tailored training and mentorship to elevate their project
              management capabilities and enable them to oversee internal
              projects confidently. Through collaborative efforts and strategic
              initiatives, we delivered a revamped website and equipped SLV with
              the tools and skills necessary to thrive in future endeavours.
              Proud to have played a pivotal role in driving digital
              transformation at SLV, I look forward to continuing to leverage my
              expertise to propel our organization toward even greater success.
            </p>

            <p className="pb-3">
              As the lead overseeing the{' '}
              <strong>State Library of Victoria’s (SLV) SLV2.0</strong> project,
              my role was pivotal in orchestrating the redesign and
              redevelopment of the library’s corporate website, aimed at
              delivering a cutting-edge user experience for the 21st century.
              This project was the library’s primary online gateway,
              facilitating seamless access to its extensive collections,
              catalogues, digitized resources, and licensed databases. My
              accomplishments include:
            </p>

            <p className="pb-3">
              <strong>Empowering Library Staff:</strong> Implemented
              comprehensive training programs to elevate the project management
              skills of library staff, enabling them to undertake project
              management responsibilities confidently.
            </p>
            <p className="pb-3">
              <strong>Streamlining Project Management:</strong> Introduced and
              streamlined the project management process, ensuring optimal
              efficiency and effectiveness in project execution.
            </p>
            <p className="pb-3">
              <strong>Risk Management Enhancement:</strong> Consolidated risks
              and issues and implemented robust mitigation plans to proactively
              address challenges and minimize disruptions.
            </p>
            <p className="pb-3">
              <strong>Governance Best Practices:</strong> Introduced best
              practices in project governance, fostering accountability,
              transparency, and alignment with organizational objectives.
            </p>

            <p className="pb-3">
              These achievements contributed to the successful realization of
              SLV 2.0 and positioned the State Library of Victoria as a leader
              in digital innovation and user-centric design. I am proud to have
              played a key role in driving this transformation and look forward
              to continuing to leverage my expertise in future endeavours.{' '}
            </p>

            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319] pt-4"></div>
            <div className="pt-4 font-bold">
              <LinkButton linktitle="Work" destination="work" turning="up" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Harbinger;
