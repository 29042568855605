import About from './components/About';
import DeckBuild from './components/DeckBuild';
import Home from './components/Home';
import Navbar from './components/Navbar';
import ProfileWeb from './components/ProfileWeb';
import Projects from './components/Projects';
import Rc82Website from './components/Rc82Website';
import WineBarrel from './components/WineBarrel';
import MacroPhoto from './components/MacroPhoto';
import Work from './components/Work';
import BureauOfMet from './components/BureauOfMet';
import Harbinger from './components/Harbinger';
import Immersive from './components/Immersive';
import DepPrimaryInd from './components/DepPrimaryInd';
import Virtusa from './components/Virtusa';

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      <Work />
      <Projects />
      <ProfileWeb />
      <Rc82Website />
      <WineBarrel />
      <DeckBuild />
      <MacroPhoto />
      <BureauOfMet />
      <Harbinger />
      <Immersive />
      <DepPrimaryInd />
      <Virtusa />
    </div>
  );
}

export default App;
