import React from 'react';
import LinkButton from './LinkButton';

const Work = () => {
  return (
    <div
      name="work"
      className=" bg-[#3f3319] w-full lg:h-[2750px] md:h-[3200px] sm:h-[2550px] h-[5200px] items-center pt-24"
    >
      {/* Work Experience Container */}
      {/* justify-center */}
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10 lg:h-[2650px] md:h-[3100px] h-full sm:h-full  bg-yellow-50 rounded-xl shadow-xl shadow-[#92866c]">
        {/* Work Experience Main Header */}
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          {/* First Column */}
          <div className="sm:text-right pb-8 pt-[50px] sm:pt-12 md:pt-[50px]">
            <p className="text-4xl font-bold inline border-b-4 text-[#3f3319] border-[#3f3319]">
              Work Experience
            </p>
          </div>
          {/* Second Column */}
          <div></div>
        </div>

        {/* Two column grid for greater than small screens */}
        <div className="max-w-[1000px] w-full grid justify-items-stretch sm:grid-cols-2 px-4">
          {/* Bureau of Meteorology - BOM*/}
          <div className="sm:text-left font-bold text-2xl text-[#b49d6b] w-[80%]">
            <p className=" pb-[20px]">Bureau of Meteorology</p>
            <div className="sm:text-left pl-[20px] font-bold text-[15px] sm:text-[20px] md:text-[20px] justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">
                <strong>Start Date:</strong> May 2009
              </p>
              <p>
                <strong>End Date: </strong>todate
              </p>
              <p>
                <strong>Position: </strong>Senior Project Manager
              </p>
            </div>
          </div>

          <div>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              The Bureau of Meteorology Australia is the nation's premier
              weather, climate, and water information authority. With a history
              of over a century, the Bureau plays a vital role in safeguarding
              Australian communities, industries, and the environment by
              providing accurate and timely meteorological services.
            </p>
            <div className="font-bold pt-4">
              <LinkButton linktitle="Read more" destination="bureauofmet" />
            </div>

            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319]"></div>
          </div>

          {/* Harbinger Private LTD */}
          <div className="sm:text-left font-bold text-2xl text-[#b49d6b] w-[80%]">
            <p className=" pb-[20px]">Harbinger Pty LTD</p>
            <div className="sm:text-left pl-[20px] font-bold text-[15px] sm:text-[20px] md:text-[20px] justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">
                <strong>Start Date: </strong>Jan 2009
              </p>
              <p>
                <strong>End Date: </strong>May 2009
              </p>
              <p>
                <strong>Position: </strong>Senior Project Manager
              </p>
            </div>
          </div>

          <div>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              Harbinger Pty Ltd is a dynamic and forward-thinking consultancy
              firm specializing in strategic advisory services and business
              solutions. With a focus on delivering tangible results and driving
              sustainable growth, Harbinger provides tailored strategies and
              actionable insights to help organizations navigate complex
              challenges and seize opportunities in today's competitive
              landscape.
            </p>
            <div className="font-bold pt-4">
              <LinkButton linktitle="Read more" destination="harbinger" />
            </div>
            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319]"></div>
          </div>

          {/* Immersive Private LTD */}
          <div className="sm:text-left font-bold text-2xl text-[#b49d6b] w-[80%]">
            <p className=" pb-[20px]">Immersive Pty LTD</p>
            <div className="sm:text-left pl-[20px] font-bold text-[15px] sm:text-[20px] md:text-[20px] justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">
                <strong>Start Date: </strong>Jun 2008
              </p>
              <p>
                <strong>End Date: </strong>Oct 2008
              </p>
              <p>
                <strong>Position: </strong>Senior Project Manager
              </p>
            </div>
          </div>

          <div>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              Immersive Pty Ltd is an innovative technology company specializing
              in automation and developing service-based software solutions.
            </p>
            <div className="font-bold pt-4">
              <LinkButton linktitle="Read more" destination="immersive" />
            </div>
            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319]"></div>
          </div>

          {/* Department of Primary Industries DPI */}
          <div className="sm:text-left font-bold text-2xl text-[#b49d6b] w-[80%]">
            <p className=" pb-[20px]">Department of Primary Industries - DPI</p>
            <div className="sm:text-left pl-[20px] font-bold text-[15px] sm:text-[20px] md:text-[20px] justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">
                <strong>Start Date: </strong>Mar 2006
              </p>
              <p>
                <strong>End Date: </strong>Jun 2008
              </p>
              <p>
                <strong>Position: </strong>Senior Project Manager
              </p>
            </div>
          </div>

          <div>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              The Department of Primary Industries Victoria is a pivotal
              governmental organization that fosters sustainable growth and
              innovation within Victoria's primary industries. With a focus on
              agriculture, fisheries, and forestry, the department plays a vital
              role in supporting the state's economic, social, and environmental
              well-being.
            </p>
            <div className="font-bold pt-4">
              <LinkButton linktitle="Read more" destination="depprimaryind" />
            </div>
            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319]"></div>
          </div>

          {/* Virtusa Pty LTD */}
          <div className="sm:text-left font-bold text-2xl text-[#b49d6b] w-[80%]">
            <p className=" pb-[20px]">VIRTUSA Pty LTD</p>
            <div className="sm:text-left pl-[20px] font-bold text-[15px] sm:text-[20px] md:text-[20px] justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">
                <strong>Start Date: </strong>May 2002
              </p>
              <p>
                <strong>End Date: </strong>Jan 2006
              </p>
              <p>
                <strong>Position: </strong>Project Manager
              </p>
            </div>
          </div>

          <div>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              Virtusa Pty Ltd is a prominent global digital engineering and
              information technology outsourcing services provider, CMMI level 5
              certified. With a steadfast commitment to innovation and client
              success, Virtusa delivers cutting-edge solutions that empower
              businesses to thrive in today's rapidly evolving digital
              landscape.
            </p>
            <div className="font-bold pt-4">
              <LinkButton linktitle="Read more" destination="virtusa" />
            </div>
            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319]"></div>
          </div>

          {/* AsiaSoft Pty LTD */}
          <div className="sm:text-left font-bold text-2xl text-[#b49d6b] w-[80%]">
            <p className=" pb-[20px]">AISASOFT Pty LTD</p>
            <div className="sm:text-left pl-[20px] font-bold text-[15px] sm:text-[20px] md:text-[20px] justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">
                <strong>Start Date: </strong>Dec 2000
              </p>
              <p>
                <strong>End Date: </strong>Apr 2002
              </p>
              <p>
                <strong>Position: </strong>Project Manager
              </p>
            </div>
          </div>

          <div>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              AsiaSoft Private Limited is a distinguished software company
              specializing in developing innovative software solutions tailored
              for the healthcare sector in the United States, alongside
              project-based financial applications designed to cater to the
              unique needs of Non-Governmental Organizations (NGOs) in Sri
              Lanka. With a dedicated team of approximately 80 skilled
              engineers, AsiaSoft is committed to delivering high-quality
              products and services. The company boasts an impressive annual
              revenue of Rs. 50.0M.
            </p>
            <div className="font-bold pt-4">
              <LinkButton linktitle="Read more" destination="home" />
            </div>
            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319]"></div>
          </div>

          {/* Hatton Nation Bank Pty LTD */}
          <div className="sm:text-left font-bold text-2xl text-[#b49d6b] w-[80%]">
            <p className=" pb-[20px]">Hatton National Bank Pty LTD</p>
            <div className="sm:text-left pl-[20px] font-bold text-[15px] sm:text-[20px] md:text-[20px] justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">
                <strong>Start Date: </strong>Sep 1984
              </p>
              <p>
                <strong>End Date: </strong>Dec 2000
              </p>
              <p>
                <strong>Position: </strong>Assistant Manager - Remote Banking
              </p>
            </div>
          </div>

          <div>
            <p className="text-[#000000]  py-4 max-w-[700px]">
              <p>
                Hatton National Bank (HNB) is a leading banking institution in
                Sri Lanka, renowned for its commitment to excellence and
                innovation in financial services. With a rich heritage from
                1888, HNB has established itself as a trusted partner for
                individuals, businesses, and communities nationwide.
              </p>
              <p>
                I worked in different capacities in the bank for sixteen long
                years.
              </p>
              <ul>
                <li>Bank Clark - started as a bank clerk,</li>
                <li>Systems Operator - moved to the IT division,</li>
                <li>Team Leader - got promoted to Team Lead role,</li>
                <li>
                  Software Engineer - moved into Banking Applications
                  development,
                </li>
                <li>
                  Software Engineer - got assigned to Remote Banking
                  Applications development, and
                </li>
                <li>
                  Assistant Manager - led the Remote Banking Applications team.
                </li>
              </ul>
            </p>
            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319]"></div>
          </div>
          <div className="pt-[50px] font-bold">
            <LinkButton linktitle="Home" destination="home" turning="up" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
