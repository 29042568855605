import React from 'react';
import LinkButton from './LinkButton';
// import ProfileWeb from './ProfileWeb';

const Projects = () => {
  return (
    <div
      name="projects"
      // className="w-full h-[2600px] sm:h-[1600px] pt-24 bg-[#3f3319] text-gray-400"
      className=" bg-[#3f3319] w-full lg:h-[1700px] md:h-[1700px] sm:h-[1600px] h-[2900px] items-center pt-24"
    >
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10 lg:h-[1600px] md:h-[1600px] sm:h-full h-[2800px] bg-yellow-50 rounded-xl shadow-xl shadow-[#92866c]">
        {/* Work Experience Main Header ------------ */}
        {/* Page title ----------------------------- */}
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pt-[50px] sm:pt-4">
            <p className="sm:text-4xl text-3xl font-bold inline border-b-4 text-[#3f3319] border-[#3f3319]">
              My Projects
            </p>
          </div>
          <div></div>
        </div>

        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          {/* Manoshan Porfolio Website */}
          <div className="sm:text-left bg-white-900 rounded-xl h-[300px] font-bold text-xl text-[#b49d6b] w-[100%] shadow-xl mb-8">
            <p className=" pb-[20px] pl-4">
              Manoshan Domingo Portfolio Website
            </p>
            <div className="sm:text-left pl-[30px] font-bold pb-8 text-xl justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">Start Date: Feb 2024</p>
              <p>End Date: Feb 2024</p>
            </div>
            {/* Drawing a line in between */}
            <hr className="bg-[#d0c9b5] h-1" />

            <div className="pl-[25px] pt-6">
              <LinkButton linktitle="More Details" destination="profileweb" />
            </div>
          </div>

          {/* Royal College 82 Group website */}
          <div className="sm:text-left bg-white-900 rounded-xl h-[300px] font-bold text-xl text-[#b49d6b] w-[100%] shadow-xl mb-8">
            <p className=" pb-[20px] pl-4">Royal College Group of 82 Website</p>
            <div className="sm:text-left pl-[30px] font-bold pb-8 text-xl justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">Start Date: Apr 2018</p>
              <p>End Date: July 2018</p>
            </div>

            {/* Drawing a line in between */}
            <hr className="bg-[#d0c9b5] h-1" />

            <div className="pl-[25px] pt-6">
              <LinkButton linktitle="RC82webSite" destination="rc82website" />
            </div>
          </div>

          {/* Wine Barrel Cabinet Build */}
          <div className="sm:text-left bg-white-900 rounded-xl h-[300px] font-bold text-xl text-[#b49d6b] w-[100%] shadow-xl mb-8">
            <p className=" pb-[20px] pl-4 ">Wine Barrel Cabinet</p>
            <div className="sm:text-left pl-[30px] font-bold pb-8 text-xl justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">Start Date: Feb 2024</p>
              <p>End Date: Feb 2024</p>
            </div>

            {/* Drawing a line in between */}
            <hr className="bg-[#d0c9b5] h-1" />

            <div className="pl-[25px] pt-6">
              <LinkButton linktitle="Barrel-Cabinet" destination="winebarrel" />
            </div>
          </div>

          {/* Deck Build */}
          <div className="sm:text-left bg-white-900 rounded-xl h-[300px] font-bold text-xl text-[#b49d6b] w-[100%] shadow-xl mb-8">
            <p className=" pb-[20px] pl-4">Home Deck Build</p>
            <div className="sm:text-left pl-[30px] font-bold pb-8 text-xl justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">Start Date: Feb 2024</p>
              <p>End Date: Feb 2024</p>
            </div>

            {/* Drawing a line in between */}
            <hr className="bg-[#d0c9b5] h-1" />

            <div className="pl-[25px] pt-6">
              <LinkButton linktitle="Deck-Build" destination="deckbuild" />
            </div>
          </div>

          {/* Picket Fence Build */}
          <div className="sm:text-left bg-white-900 rounded-xl h-[300px] font-bold text-xl text-[#b49d6b] w-[100%] shadow-xl mb-8">
            <p className=" pb-[20px] pl-4">Picket Fence Build</p>
            <div className="sm:text-left pl-[30px] font-bold pb-8 text-xl justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">Start Date: Feb 2024</p>
              <p>End Date: Feb 2024</p>
            </div>

            {/* Drawing a line in between */}
            <hr className="bg-[#d0c9b5] h-1" />

            <div className="pl-[25px] pt-6">
              <LinkButton linktitle="Fence-Build" destination="fencebuild" />
            </div>
          </div>

          {/* Garage Renovation */}
          <div className="sm:text-left bg-white-900 rounded-xl h-[300px] font-bold text-xl text-[#b49d6b] w-[100%] shadow-xl mb-8">
            <p className=" pb-[20px] pl-4">Garage Renovation</p>
            <div className="sm:text-left pl-[30px] font-bold pb-8 text-xl justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">Start Date: Feb 2024</p>
              <p>End Date: Feb 2024</p>
            </div>

            {/* Drawing a line in between */}
            <hr className="bg-[#d0c9b5] h-1" />

            <div className="pl-[25px] pt-6">
              <LinkButton linktitle="Garage-Reno" destination="garagereno" />
            </div>
          </div>

          {/* Photography */}
          <div className="sm:text-left bg-white-900 rounded-xl h-[300px] font-bold text-xl text-[#b49d6b] w-[100%] shadow-xl mb-8">
            <p className=" pb-[20px] pl-4">Macro Photography</p>
            <div className="sm:text-left pl-[30px] font-bold pb-8 text-xl justify-self-start text-[#b49d6b] w-[100%] ">
              <p className="">Start Date: Feb 2024</p>
              <p>End Date: Feb 2024</p>
            </div>

            {/* Drawing a line in between */}
            <hr className="bg-[#d0c9b5] h-1" />

            <div className="pl-[25px] pt-6">
              <LinkButton
                linktitle="Macro-Photography"
                destination="macrophotography"
              />
            </div>
          </div>

          <div className="pt-4 font-bold">
            <LinkButton linktitle="Home" destination="home" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
