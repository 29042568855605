import React from 'react';
import LinkButton from './LinkButton';

const BureauOfMet = () => {
  return (
    <div
      name="bureauofmet"
      className=" bg-[#3f3319] w-full lg:h-[2400px] md:h-[3100px] sm:h-[2400px] h-[5200px] items-center pt-24"
    >
      {/* Bureau Experience Container */}
      {/* justify-center */}
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10  lg:h-[2300px] md:h-[3000px] sm:h-[2400px] h-full bg-yellow-50 rounded-xl shadow-xl shadow-[#fdefd0]">
        {/* Bureau Experience Main Header */}
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pt-[50px] sm:pt-4">
            <p className="text-4xl font-bold inline border-b-4 text-[#3f3319] border-[#3f3319]">
              Bureau of Meteorology
            </p>
          </div>
          <div></div>
        </div>

        {/* Tow column grid when greater than small screens */}
        <div className="max-w-[1000px] grid sm:grid-cols-10 justify-items-stretch px-2 gap-8">
          {/* First Column is 30% or one third of the screen */}
          <div className="sm:col-span-3">
            <div className="pt-3">
              From <strong>2022 June</strong>
            </div>
            <div>
              <strong>to Date</strong>
            </div>
          </div>

          {/* Second column is two third or 70% of the screen */}
          <div className="sm:col-span-7">
            <p className="pb-3 pt-3">
              In 2021, I embarked on a brief yet impactful journey as a Senior
              Project Manager for the{' '}
              <strong>Hawkesbury-Nepean Valley (HNV)</strong> project.
              Recognizing the urgent need for experienced project management
              across various initiatives, I transitioned into leading several
              critical projects within the{' '}
              <strong>Water Information Transition (WIT)</strong> program. My
              current portfolio includes managing the{' '}
              <strong>
                Water Regulations Administrative Data Management System
                (WRADMS), National Performance Report Implementation (NPR
                Implementation), and the Agri-Climate Outlooks (ACO)
              </strong>{' '}
              projects.
            </p>
            <p className="pb-3">
              I am temporarily stewarding the ACO project until we welcome a
              permanent manager to our leadership team. This transition period
              highlights the dynamic nature of project management and the need
              for adaptable, skilled professionals ready to steer projects
              toward success, regardless of the domain.
            </p>
            <p className="pb-3">
              Each project presents unique challenges and opportunities for
              growth, reinforcing my commitment to excellence in project
              management in the water and climate sectors. I'm excited about our
              progress and look forward to continuing to contribute to our
              collective goals and successes.
            </p>
            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319]"></div>
          </div>
        </div>

        {/* Tow column grid when greater than small screens */}
        <div className="max-w-[1000px] grid sm:grid-cols-10 justify-items-stretch px-2 gap-8">
          {/* First Column is 30% or one third of the screen */}
          <div className="sm:col-span-3">
            <div className="pt-3">
              From <strong>2021 October</strong>
            </div>
            <div>
              to <strong>2022 June</strong>
            </div>
          </div>

          {/* Second column is two third or 70% of the screen */}
          <div className="sm:col-span-7">
            <p className="pb-3 pt-3">
              I am delighted to share a milestone achievement in my journey as a
              Senior Project Manager: leading the completion of the{' '}
              <strong>Forewarned is Forearmed (FWFA)</strong> project, also
              known as Extreme MLA, to the utmost satisfaction of our esteemed
              client, Meat & Livestock Australia. This endeavour showcased our
              team's dedication and expertise and earned us the prestigious{' '}
              <strong>Excellence Award</strong> from the Bureau during the 2022
              award ceremony.
            </p>
            <p className="pb-3">
              Managing this project was a remarkable experience, especially
              considering its completion well within the allocated budget and
              timeframe. With a total project cost of{' '}
              <strong>AUD 5.2 million</strong>, this accomplishment speaks
              volumes about our team's ability to deliver high-quality results
              efficiently and effectively.
            </p>
            <p className="pb-3">
              I extend my heartfelt gratitude to my team for their hard work and
              dedication. This achievement underscores our commitment to
              excellence and our capability to exceed client expectations.
              Here's to many more successes and continued excellence in our
              future projects!
            </p>

            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319]"></div>
          </div>
        </div>

        {/* Tow column grid when greater than small screens */}
        <div className="max-w-[1000px] grid sm:grid-cols-10 justify-items-stretch px-2 gap-8">
          {/* First Column is 30% or one third of the screen */}
          <div className="sm:col-span-3 pt-3">
            <div>
              From <strong>2016 December</strong>
            </div>
            <div>
              to <strong>2021 October</strong>
            </div>
          </div>

          {/* Second column is two third or 70% of the screen */}
          <div className="sm:col-span-7">
            <p className="pb-3 pt-3">
              As a Senior Project Manager, I led the successful execution of the
              Australian Community Climate Earth-System Simulator – Seasonal
              Model (ACCESS-S) project within the supercomputer realm.
              Overseeing both phases, I assumed full accountability for project
              delivery, meticulously managed budgets, and ensured adherence to
              specified timelines.
            </p>
            <p className="pb-3">
              My role involved seamless coordination across six distinct
              sections: National Forecasting Services (NFS), Science &
              Innovation Group (SIG), DevOps Team, Research to Operations (R2O),
              High-Performance Computing (HPC), and IT Command Centre (ITCC).
              With a keen focus on risk and issue management, I adeptly
              navigated challenges while consistently meeting and exceeding
              internal and external stakeholder expectations.
            </p>
            <p className="pb-3">
              My leadership culminated in successfully deploying ACCESS-S2, a
              high-quality replacement for the longstanding POAMA model utilized
              by the Bureau of Meteorology (BoM) for over a decade. This
              transformative endeavour, completed at approximately{' '}
              <strong>AUD 17 million</strong>, engaged over 30 dedicated staff
              members across the project's two phases.
            </p>
            <p className="pb-3">
              I am immensely proud of our collective achievements and look
              forward to leveraging this experience to drive future success in
              complex project environments.
            </p>

            {/* Draw line below the content */}
            <div class="border-b-4 border-[#3f3319]"></div>
          </div>
        </div>

        {/* Tow column grid when greater than small screens */}
        <div className="max-w-[1000px] grid sm:grid-cols-10 justify-items-stretch px-2 gap-8">
          {/* First Column is 30% or one third of the screen */}
          <div className="sm:col-span-3">
            <div className="pt-3">
              From <strong>2009 May</strong>
            </div>
            <div>
              {' '}
              to <strong>2016 December</strong>
            </div>
          </div>

          {/* Second column is two third or 70% of the screen */}
          <div className="sm:col-span-7">
            <p className="pb-3 pt-3">
              As a pivotal figure in the Climate Information Technology Section,
              I led the charge to enhance project management processes and
              methodologies, specifically within the Climate and Oceans Branch
              (COB). My role demanded full accountability for various project
              management and business analysis tasks, aligning closely with the
              bureau's high-priority initiatives.
            </p>
            <p className="pb-3">
              <strong> Key Contributions:</strong>{' '}
            </p>
            <p className="pb-3">
              <strong>Process Optimization: </strong>Spearheaded the initiative
              to streamline and implement advanced project management processors
              and methodologies, significantly boosting operational efficiency
              within the COB.
            </p>
            <p className="pb-3">
              <strong>Project Leadership: </strong>Successfully managed a
              diverse portfolio of critical projects, including the Spatial
              Technology Demonstration (STD) Project, Quality Monitoring System
              (QMS), Climate Data Online (CDO), The Climate Zone (TCZ), ADAM
              Ingest, and ADAM Migration.
            </p>
            <p className="pb-3">
              <strong>Major Migration Project: </strong>Directed the significant
              HPUX to LINUX Migration, a cornerstone project for the Climate IT
              section. This project, which culminated in substantial maintenance
              cost savings, was lauded for exceeding management's expectations
              in both execution and outcome.
            </p>
            <p className="pb-3">
              <strong>Mentorship: </strong>I was Dedicated to fostering talent
              within the Climate division. I mentored staff in enhancing their
              project management and business analysis competencies,
              contributing to a culture of continuous improvement and
              excellence.
            </p>
            <p className="pb-3">
              <strong>Business Case Development: </strong>I actively
              participated in preparing business cases and enhancing project
              management processes, ensuring a robust framework for future
              projects. Through these initiatives, I contributed to tangible
              improvements in project delivery and efficiency. I played a
              crucial role in mentoring and developing the next generation of
              project management professionals within the bureau.
            </p>
            <div class="border-b-4 border-[#3f3319]"></div>
            <div className="pt-4 font-bold">
              <LinkButton linktitle="Work" destination="work" turning="up" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BureauOfMet;
