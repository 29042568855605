import React from 'react';
import LinkButton from './LinkButton';

const MacroPhoto = () => {
  return (
    <div
      name="macrophotography"
      className="w-full lg:h-[1000px] md:h-[1300px] sm:h-[1000px] h-[1700px] pt-24 bg-[#3f3319] text-gray-400"
    >
      <div className="flex flex-col max-w-[100%] sm:max-w-[1000px] mx-auto px-10 lg:h-[900px] md:h-[1200px] sm:h-[1000px]  h-[1600px]  bg-yellow-50 rounded-xl shadow-xl shadow-[#92866c]">
        {/* Work Experience Main Header */}
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pt-[50px] sm:pt-4">
            <p className="text-4xl font-bold inline border-b-4 text-[#3f3319] border-[#3f3319]">
              Macro Photography
            </p>
          </div>
          <div></div>
        </div>

        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          {/* Manoshan Porfolio Website */}
          <div className="sm:text-left text-3xl text-[#b49d6b] w-[80%]">
            <p className=" pb-[20px]">Macro Photography</p>
            <div className="sm:text-left pl-[30px]  text-xl justify-self-start text-[#b49d6b] w-[80%] ">
              <p className="">
                <strong>Start Date: </strong>Feb 2024
              </p>
              <p>
                <strong>End Date: </strong>Feb 2024
              </p>
              <div></div>
            </div>
          </div>

          <div>
            <p className="text-[#7a6d52]  py-4 max-w-[700px]">
              Welcome to my portfolio website! I am excited to showcase my
              learning and growth journey in web development. Armed with a
              newfound expertise in React programming, I embarked on a mission
              to craft a dynamic and user-friendly website. Through diligent
              research and exploration of best practices on the web, I
              meticulously designed every aspect of this site to ensure optimal
              user experience and visual appeal. Leveraging my skills and
              creativity, I developed and refined the website, meticulously
              honing its functionality and aesthetics to meet the highest
              standards. Today, I am proud to present the culmination of my
              efforts—a website that reflects my passion for technology,
              innovation, and excellence. Thank you for visiting, and I hope you
              enjoy exploring my portfolio as much as I enjoyed creating it.
            </p>
            <p className="text-[#7a6d52]  py-4 max-w-[700px]">
              I used the following resources to learn React:
              <ul>
                <li>Udemy web development course library,</li>
                <li>YouTube - followed several React tutorials, and</li>
                <li>TailWind CSS website etc.</li>
              </ul>
            </p>
            <p className="text-[#7a6d52]  py-4 max-w-[700px]">
              <strong>Note: </strong>Please let me know any suggestions you
              identified by scanning through my site. I am not perfect, but I
              can improve the site with your feedback. Please use the contact
              form to send me your suggestions.
            </p>
            <div className="pt-4 font-bold">
              {' '}
              <LinkButton
                linktitle="My Projects"
                destination="projects"
                turning="up"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MacroPhoto;
